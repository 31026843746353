import React, { useRef, useEffect, useState } from "react";
import style from "./Learnings.module.css";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import AreaChart from "../../assests/areaChart.png";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import LeaderImage from "../../assests/wgferg2 5.png";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { baseurl } from "../Url";
import { useNavigate } from "react-router-dom";
import Loader from "../Loder/Loader";
import dayjs from "dayjs";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Learnings = () => {

  const navigate=useNavigate()

  const UserID = localStorage.getItem("USERID");
  const ClubID = localStorage.getItem("ClubID");
  const OrgID = localStorage.getItem("ORGID");
  const key = JSON.parse(localStorage.getItem("access-token"));


  const [selectedModule, setSelectedModule] = useState(0);
  const [learningData, setLearningData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTab,setSelectedTab]=useState(0)
  const [learningPaceData,setLearningPaceData]=useState({})
  const [learningLevelData,setLearningLevelData]=useState({})
  const [learningLevelModID,setLearningLevelModID]=useState("");
  const [learningPaceModID,setLearningPaceModID]=useState("");
  const [dateForLP, setDateForLP] = useState("weekly");


  const handleLPDate =(date)=>{
    setDateForLP(date)
   
  }

  const toggleExpand = (index) => {
    setLearningData((prevData) => {
      const updatedTopics = prevData.Topics.map((topic, i) => {
        if (i === index) {
          return { ...topic, isExpand: !topic.isExpand };
        }
        return topic;
      });
      return { ...prevData, Topics: updatedTopics };
    });
  };

  const learningPaceLabels = learningPaceData?.data?.map(item => dayjs(item.period).format('DD')); 
  const firstLLDate = learningPaceData?.data?.length > 0 ? learningPaceData?.data[0]["period"] : "";
  const lLMonthName = firstLLDate ? dayjs(firstLLDate).format('MMM') : "";
const topicCounts =learningPaceData?.data?.map(item => item.topic_count); // Extracting topic counts for y-axis

const learningPaceChartData = {
    labels: learningPaceLabels,
    datasets: [
        {
            label: 'Topic Count',
            data: topicCounts,
            backgroundColor: '#FF8800', 
            barThickness: 20, 
            borderRadius: 10, 
        }
    ]
};

const learningPaceOptions = {
  responsive: true,
  plugins: {
      legend: {
          display: false,
      },
      tooltip: {
          callbacks: {
              label: function (context) {
                  return `Topic ${context.dataIndex + 1}: ${context.raw}`;
              },
          },
      },
  },
  scales: {
      x: {
          title: {
              display: true,
              text: `Day (${lLMonthName})`,
          },
          grid: {
              display: false,
          },
          beginAtZero: false,
          ticks: {
              stepSize: 1,
          },
      },
      y: {
          title: {
              display: true,
              text: 'Total no.of topics',
          },
          beginAtZero: true,
          max: learningPaceData.module_topics_count, 
          ticks: {
              stepSize: 1, 
              callback: function(value) {
                  return value; 
              },
          },
      },
  },
};

const learningLevelGraphData = {
  labels: [...Array(learningLevelData?.module_topics_count).keys()], 
  datasets: [
    {
      label: 'Baseline Score',
      data: learningLevelData?.data?.map((topicArray) => {
        const baselineData = topicArray.find(topic => topic.score_level === 'baseline');
        return baselineData ? parseFloat(baselineData.score) : null;
      }), 
      backgroundColor: '#595BDB', 
      barThickness: 20,
      borderRadius: 10, 
    },
    {
      label: 'Current Score',
      data: learningLevelData?.data?.map((topicArray) => {
        const currentData = topicArray.find(topic => topic.score_level === 'current');
        return currentData ? parseFloat(currentData.score) : null;
      }), 
      backgroundColor: '#FFBE00', 
      barThickness: 20,
      borderRadius: 10, 
    },
  ],
};

const learningLevelOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function (context) {
          const topicIndex = context[0].dataIndex + 1;
          return `Topic ${topicIndex}`;
        },
        label: function (context) {
          return `${context.dataset.label}: ${context.raw}%`;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Topics',
      },
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        callback: function (value) {
          return value + 1; 
        },
      },
    },
    y: {
      title: {
        display: true,
        text: 'Score (%)',
      },
      beginAtZero: true,
      max: 100, 
      ticks: {
        stepSize: 20,
        callback: function (value) {
          return value + '%'; 
        },
      },
    },
  },
};


  async function learningList() {
    await fetch(`${baseurl}/userlearning/details`, {
      method: "POST",
      body: JSON.stringify({ user_id: UserID }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const initialModuleId=data.data[0]?.Topics[0].module_id
          setLearningPaceModID(initialModuleId)
         setLearningLevelModID(initialModuleId)
          const modifiedData = data.data.map((item) => {
            const topicsWithNoModuleName = [];
            const topicsWithModuleName = [];
    
            item.Topics.forEach((topic) => {
              if (!topic.module_name) {
                topic.module_name = "General topics";
                topicsWithNoModuleName.push(topic);
              } else {
                topicsWithModuleName.push(topic);
              }
            });
    
            item.Topics = [...topicsWithModuleName, ...topicsWithNoModuleName].map((topic) => {
              topic.isExpand = false;
              return topic;
            });
    
            return item;
          });
            setTimeout(() => {
              setLoading(false)
            }, 1500);
          setLearningData(modifiedData[0]);
        }
        else{
          setTimeout(() => {
            setLoading(false)
          }, 1500);
          setLearningData({})
        }
      });
  }

  async function learningPace(modID,date) {
    await fetch(`${baseurl}/performance/topic-count`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        module_id:modID,
        filter: date
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setLearningPaceData(data)
        }
        else{
          setLearningPaceData(data)
        }
      });
  }
  async function learningLevel(modID,) {
    await fetch(`${baseurl}/performance/learning-level`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        module_id:modID,
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setLearningLevelData(data)
        }
        else{
          setLearningLevelData()
        }
      });
  }

  useEffect(() => {
    learningList();
  }, [UserID]);

  useEffect(()=>{
    learningPace(learningPaceModID,dateForLP)
  },[learningPaceModID,dateForLP])

  useEffect(()=>{
    learningLevel(learningLevelModID)
  },[learningLevelModID])

  return (
    <div className={style.container}>
      {loading?(
        <Loader/>
      )
      :(
      <>
      {Object.keys(learningData).length === 0 &&!loading ? (
      <div className={style.info}><p>No data found</p></div>
    ) : (
      <div className={style.learningSection}>
        <div className={style.learningHeader}>
          <h3>{learningData?.category_details}</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
            <br />
            Lorem Ipsum has a been the industry's standard dummy text ever since
            the 1500s,
            <br />
            when an unknown printer took a galley of type and scrambled it{" "}
            <br />
            to make a type specimen book. 
          </p>
        </div>
        <div className={style.graphSection}>
          <div className={style.graphSectionLeft}>
            <div className={style.graphSectionLeftTop}>
              <div onClick={()=>setSelectedTab(0)} className={selectedTab===0?style.selectedTab:style.tab}></div>
              <div  onClick={()=>setSelectedTab(1)} className={selectedTab===1?style.selectedTab:style.tab}></div>
            </div>
            <div className={style.graphContent}>
              {selectedTab===0&&(
                <div className={style.graphData}>
                <div className={style.graphDataHeader}>
                  <p>Learning Pace</p>
                </div>
                <div className={style.graphDataSubHeader}  >
                <div className={style.graphDataSubHeaderLeft}>
                 <div className={`${style.subHeaderCard} ${dateForLP==="weekly"?style.selectedSubHeaderCard:""}`}
                  onClick={() => handleLPDate("weekly")}>
                  <p>W</p>
                 </div>
                 <div className={`${style.subHeaderCard} ${dateForLP==="monthly"?style.selectedSubHeaderCard:""}`}
                 onClick={() => handleLPDate("monthly")}>
                  <p>M</p>
                 </div>
                 <div className={`${style.subHeaderCard} ${dateForLP==="yearly"?style.selectedSubHeaderCard:""}`}
                 onClick={() => handleLPDate("yearly")}>
                  <p>Y</p>
                 </div>
                 </div>
                 <div className={style.graphDataSubHeaderRight}>
                 <select
                 value={learningPaceModID}
                 onChange={(e)=>setLearningPaceModID(e.target.value)}
                 >
                  {learningData.Topics&&learningData.Topics.length>0&&learningData.Topics.map((mod)=>(
                    <option key={mod.module_id} value={mod.module_id}>{mod.module_name}</option>
                  ))}
                 </select>
                 </div>
                </div>
                
                <div className={style.barGraph}>
                <Bar data={learningPaceChartData} options={learningPaceOptions} />
                </div>
              </div>
              )}
              {selectedTab===1&&(
                <div className={style.graphData}>
                <div className={style.graphDataHeader}>
                    <p>Learning Level</p>
                  </div>
                  <div className={style.graphDataSubHeader}>
                  <div className={style.levelHeader}>
                  <div className={style.levelHeaderCard}>
                    <span></span>
                    <h4>Current Score</h4>
                    <p>(The mark scored in the last attempt)
                    </p>
                  </div>
                  <div className={style.levelHeaderCard}>
                    <span></span>
                    <h4>Baseline score</h4>
                    <p>(The mark scored in the first attempt)
                    </p>
                  </div>
                  </div>
                   <div className={style.graphDataSubHeaderRight}>
                   <select
                   value={learningLevelModID}
                   onChange={(e)=>setLearningLevelModID(e.target.value)}
                   >
                   {learningData.Topics&&learningData.Topics.length>0&&learningData.Topics.map((mod)=>(
                    <option key={mod.module_id} value={mod.module_id}>{mod.module_name}</option>
                  ))}
                   </select>
                   </div>
                  </div>
                  <div className={style.barGraph}>
                  <Bar
                    data={learningLevelGraphData}
                    options={learningLevelOptions}
                  />
                  </div>
                </div>
              )}

            </div>
          </div>

          <div className={style.graphSectionRight}>
            <div className={style.leaderBoardHeader}>
              <p>Leaderboard</p>
            </div>
            <div className={style.leaderBoardBody}>
              <div
                style={{ background: "#FFBE00B2" }}
                className={style.leaderBoardCard}
              >
                <div className={style.leaderCardLeft}>
                  <div className={style.leaderCardLeftTop}>
                    <div className={style.leaderName}>
                      <p>Eliza</p>
                    </div>
                    <div className={style.leaderPoint}>
                      <p>212 / 300</p>
                    </div>
                  </div>
                  <div className={style.leaderCardLeftBottom}>
                    <div className={style.leaderProgressBAr}>
                      <div
                        className={style.progressBar}
                        style={{
                          width: "95%",
                          backgroundColor: "#fff",
                          borderRadius: "2rem",
                        }}
                      >
                        <div
                          style={{
                            width: `${70}%`,
                            backgroundColor: "#DBA300",
                            height: "12px",
                            transition: "width 0.5s ease-in-out",
                            borderRadius: "1rem",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={style.leaderAssessment}>
                      <p>12Assessment</p>
                    </div>
                  </div>
                </div>
                <div className={style.leaderCardRight}>
                  <img src={LeaderImage} alt="Leader" />
                </div>
              </div>
              <div className={style.leaderBoardCard}>
                <div className={style.leaderCardLeft}>
                  <div className={style.leaderCardLeftTop}>
                    <div className={style.leaderName}>
                      <p>John</p>
                    </div>
                    <div className={style.leaderPoint}>
                      <p>125 / 300</p>
                    </div>
                  </div>
                  <div className={style.leaderCardLeftBottom}>
                    <div className={style.leaderProgressBAr}>
                      <div
                        className={style.progressBar}
                        style={{
                          width: "95%",
                          backgroundColor: "#fff",
                          borderRadius: "2rem",
                        }}
                      >
                        <div
                          style={{
                            width: `${45}%`,
                            backgroundColor: "#595BDB",
                            height: "12px",
                            transition: "width 0.5s ease-in-out",
                            borderRadius: "1rem",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={style.leaderAssessment}>
                      <p>10Assessment</p>
                    </div>
                  </div>
                </div>
                <div className={style.leaderCardRight}>
                  <img src={LeaderImage} alt="Leader" />
                </div>
              </div>
              <div className={style.leaderBoardCard}>
                <div className={style.leaderCardLeft}>
                  <div className={style.leaderCardLeftTop}>
                    <div className={style.leaderName}>
                      <p>Sheetal</p>
                    </div>
                    <div className={style.leaderPoint}>
                      <p>69 / 300</p>
                    </div>
                  </div>
                  <div className={style.leaderCardLeftBottom}>
                    <div className={style.leaderProgressBAr}>
                      <div
                        className={style.progressBar}
                        style={{
                          width: "95%",
                          backgroundColor: "#fff",
                          borderRadius: "2rem",
                        }}
                      >
                        <div
                          style={{
                            width: `${20}%`,
                            backgroundColor: "#595BDB",
                            height: "12px",
                            transition: "width 0.5s ease-in-out",
                            borderRadius: "1rem",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={style.leaderAssessment}>
                      <p>7Assessment</p>
                    </div>
                  </div>
                </div>
                <div className={style.leaderCardRight}>
                  <img src={LeaderImage} alt="Leader" />
                </div>
              </div>
              <div className={style.leaderBoardCard}>
                <div className={style.leaderCardLeft}>
                  <div className={style.leaderCardLeftTop}>
                    <div className={style.leaderName}>
                      <p>Eliza</p>
                    </div>
                    <div className={style.leaderPoint}>
                      <p>212 / 300</p>
                    </div>
                  </div>
                  <div className={style.leaderCardLeftBottom}>
                    <div className={style.leaderProgressBAr}>
                      <div
                        className={style.progressBar}
                        style={{
                          width: "95%",
                          backgroundColor: "#fff",
                          borderRadius: "2rem",
                        }}
                      >
                        <div
                          style={{
                            width: `${10}%`,
                            backgroundColor: "#595BDB",
                            height: "12px",
                            transition: "width 0.5s ease-in-out",
                            borderRadius: "1rem",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={style.leaderAssessment}>
                      <p>12Assessment</p>
                    </div>
                  </div>
                </div>
                <div className={style.leaderCardRight}>
                  <img src={LeaderImage} alt="Leader" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.moduleSection}>
          <div className={style.moduleSectionHeader}>
            <p>Modules</p>
          </div>
          <div className={style.moduleSectionBody}>
            {learningData?.Topics?.length > 0 &&
              learningData.Topics.map((mod, i) => (
                <div  className={`${style.moduleCard}`} key={i}>
                  <div className={style.moduleCardTop}>
                    <div className={style.moduleCardName}>
                      <p>Module {i+1}</p>
                    </div>
                    <div className={style.moduleCount}>
                      {mod.topics.map((topic, j) => (
                        <div
                          key={j}
                          style={{
                            background:
                            topic.status === "complete"
                                ? "#00DFC7"
                              :topic.status === "In Progress"
                                ? "#FFB563"
                                : "#D9D9D9",
                          }}
                          className={style.moduleCountColor}
                        ></div>
                      ))}
                    </div>
                  </div>
                  <div className={style.moduleCardBottom}>
                    <div className={style.moduleCardBottomLeft}>
                      <p>{mod.module_name.replace(/Module \d+\s*:\s*/, '')}</p>
                    </div>
                    <div className={style.moduleCardBottomRight}>
                      {mod.isExpand ? (
                        <BsChevronUp onClick={() => toggleExpand(i)} />
                      ) : (
                        <BsChevronDown onClick={() => toggleExpand(i)} />
                      )}
                    </div>
                  </div>
                  {mod.isExpand && (
                    <div className={style.topicContainer}>
                      {mod.topics.map((topic, j) => (
                        <div
                        onClick={()=>{
                          navigate("/new/learnings/detail",{
                            state:{
                              mainId:mod.topic_id,
                              singleTopic: topic,
                              learning: learningData.learning_id,
                              mapped:learningData.admin_mapped_lessons,
                              topic_no:j + 1
                            }
                          })
                        }}
                          key={j}
                          
                          className={`${style.topicCard} ${
                            topic.status === "complete"
                              ? style.complete
                              : topic.status === "In Progress"
                              ? style.inProgress
                              : style.notStarted
                          } ${mod.release_status !== true ? style.disabledCard : ""}`}
                        >
                          <p> {topic.value}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    )}
    </>
  )}
    </div>
  );
};

export default Learnings;
