import React, { useEffect, useState, useRef } from "react";
import style from "./Support.module.css";
import supportImage from "../../assests/subscriptionMail.png";
import customerSupportImage from "../../assests/flat-customer-support-illustration 1.png";

const Support = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const UserName = localStorage.getItem("username");

  return (
    <div className={style.container}>
      <div className={style.support}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>Hello {UserName}!</p>
            <span>We are here to help you!</span>
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={supportImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>
        <div className={style.supportSection}>
          <div className={style.supportSectionLeft}>
            <div className={style.supportHeder}>
              <p>Support</p>
            </div>
            <div className={style.supportSubHeder}>
              <p>Need assistance? </p>
              <span>Our support team is here to help. <br/>
              Contact us directly for quick and personalized support. </span>
            </div>
            <div className={style.supportDetail}>
              <p>+44 079 5858 0357 </p>
              <span>enquiries@techbooq.com</span>
            </div>
          </div>
          <div className={style.supportSectionRight}>
            <div className={style.supportSectionImage}>
              <img src={customerSupportImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
