import React, { useState, useEffect } from "react";
import style from "./NewLearningDetail.module.css";
import LeftArrow from "../../assests/LeftArrow.png";
import { useLocation,useNavigate } from "react-router-dom";
import { baseurl } from "../Url";
import Loader from "../Loder/Loader";
import { useSelector } from "react-redux";

const NewLearningDetail = () => {

  const location = useLocation();
  const navigate =useNavigate();

  const state = location.state;
  const Topic = state?.singleTopic;
  const learning_id = state?.learning;
  const admin_Mapped_Lessons = state?.mapped;
  const main_topic_id = state?.mainId;
  const topicNo = state?.topic_no;

  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);

  const [AiId, setAiID] = useState("");
  const [topicData, setTopicData] = useState({});
  const [assessmentData,setAssessmentData]=useState({})
  const [loading, setLoading] = useState(true);
  const [trialUser,setTrailUser]=useState(false);
  const [assessmentExist,setAssessmentExist]=useState(false)

  async function Topic_Single() {
    await fetch(`${baseurl}/ai/lesson/generation/for/user`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        learning_id: learning_id,
        main_topic_id: main_topic_id,
        user_id: USERID,
        admin_mapped: admin_Mapped_Lessons,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "initial data");
        setAiID(data.data[0]?.ai_id);
        if(data.data[0]?.assessments?.length>0){
          setAssessmentExist(true)
        }
        else{
          setAssessmentExist(false)
        }
      });
  }

  async function dataGet() {
    await fetch(`${baseurl}/lesson/pagination`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        ai_id: AiId,
        user_id: USERID,
        page_num: 1,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setTopicData(data.data?.[0]|| {});
          setTimeout(() => {
          setLoading(false)
          }, 1500);
        }
        else{
          setTimeout(() => {
            setLoading(false)
          }, 1500);
          setTopicData({})
        }
        
      });
  }

  async function previousDetails() {
    await fetch(`${baseurl}/summary/previous-attended/`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
        topic_id: Topic.id,
        main_topic_id: main_topic_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setAssessmentData(data.summary||{})
        }
        else{
          setAssessmentData({})
        }
        
      });
  }

  useEffect(() => {
    Topic_Single();
   
    
  }, [location.state]);

  useEffect(() => {
    if (AiId !== undefined||AiId!=="") {
      dataGet();
      previousDetails()
    }
  }, [AiId]);

  useEffect(()=>{
    const userType=userStatus === 'succeeded' && userDetails &&userDetails.user_type
    console.log(userType);
    
    if(userType==="Trail"){
      setTrailUser(true)
    }
    else{
      setTrailUser(false)
    }
    },[])
      

  return (
    <div className={style.container}>
      {loading?(
        <Loader/>
      )
      :(
      <>
      <div className={style.learningBody}>
        <div className={style.header} style={{background:trialUser?"#FF8800":""}}>
          <div className={style.headerIcon}>
            <img onClick={()=>{
              navigate("/new/learnings")
            }} src={LeftArrow} alt="Left" />
          </div>
          <div className={style.headerText}>
            <span>Topic {topicNo}</span>
            <h4>{Topic.value}</h4>
          </div>
        </div>
        {Object.keys(topicData).length === 0 &&!loading ? (
      <div className={style.info}><p>No data found</p></div>
    ) : (
        <div className={style.learningSection}>
          <div className={style.learningContent}>
            <p
              dangerouslySetInnerHTML={{
                __html: topicData?.text ? topicData.text : "",
              }}
            ></p>
          </div>
          <div className={style.assessmentFloatingSection}>
           
            <div className={style.floatingSectionLeft}>
            
             <>
                <span>Previous Score:</span>
                <p>{assessmentData && assessmentData.correct_answers ? assessmentData.correct_answers : 0}/{assessmentData && assessmentData.total_questions ? assessmentData.total_questions : 0}</p>
             </>
            </div>
           
            <div className={style.floatingSectionRight}>
              {!trialUser ? (
                assessmentData && assessmentData.is_attended ? (
                  <button
                  disabled={!assessmentExist}
                   style={{backgroundColor:"#FFBE00"}} onClick={()=>{
                    navigate("/assessments", {
                      state: {
                        aiID: AiId,
                        singleTopic: Topic,
                        mapped: admin_Mapped_Lessons,
                        learning: learning_id,
                        mainID: main_topic_id,
                        topicNo: topicNo
                      }
                    })
                  }}>Re-Test</button>
                ) : (
                  <button disabled={!assessmentExist} onClick={()=>{
                    navigate("/assessments", {
                      state: {
                        aiID: AiId,
                        singleTopic: Topic,
                        mapped: admin_Mapped_Lessons,
                        learning: learning_id,
                        mainID: main_topic_id,
                        topicNo: topicNo
                      }
                    })
                  }}>Take a Test</button>
                )
              ) : (
                !assessmentData.is_attended && (
                  <button disabled={!assessmentExist} onClick={()=>{
                    navigate("/assessments", {
                      state: {
                        aiID: AiId,
                        singleTopic: Topic,
                        mapped: admin_Mapped_Lessons,
                        learning: learning_id,
                        mainID: main_topic_id,
                        topicNo: topicNo
                      }
                    })
                  }}>Take a Test</button>
                )
              )}
            </div>
          </div>
        </div>
         )}
      </div>  
      </>
     )} 
    </div>
  );
};

export default NewLearningDetail;
