import React, { useState,useRef } from "react";
import style from "./ResetPassword.module.css";
import LockImage from "../../assests/tiny-people-carrying-key.png";
import SuccessImage from "../../assests/PasswordSuccess.png"
import { baseurl } from "../Url";
import { useNavigate } from "react-router-dom";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";

const ResetPassword = () => {

  const navigate =useNavigate();

  const [contentShow, setContentShow] = useState("emailSection");
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState(Array(5).fill(""));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [userID,setUserID]=useState("")
  const [showPassword, setShowPassword] = useState(false);
  const inputRefs = useRef([]);
  const [buttonClicked,setButtonClicked]=useState(false)

  const handleChange = (e, i) => {
    const value = e.target.value;

    if (/^[0-9]$/.test(value) || value === "") {
      setOTP((prev) => {
        const newOtp = [...prev];
        newOtp[i] = value;
        return newOtp;
      });

      // Move focus to the next input if a value is entered
      if (value && i < inputRefs.current.length - 1) {
        inputRefs.current[i + 1].focus();
      }
    }
  };

  const handleFocus = (i) => {
    // Optionally select the text when an input gains focus
    if (inputRefs.current[i]) {
      inputRefs.current[i].select();
    }
  };


  const handleSendEmail = () => {
    if (!email || !validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    otpSend();
  };

  const handleVerifyOtp = () => {
    if (otp.length !== 5) {
      setError("OTP must be 5 characters long.");
      return;
    }
    setError("");
    otpValidate();
  };

  const handleResetPassword = () => {
    let error = false;

    if (!password || !validatePassword(password)) {
      setPasswordError(true);
      error = true;
    } else {
      setPasswordError(false);
    }

    if (!confirmPassword || password !== confirmPassword) {
      setConfirmPasswordError(true);
      error = true;
    } else {
      setConfirmPasswordError(false);
    }

    if (error) {
      return; 
    }
    passwordUpdate();
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(password) && !/\s/.test(password);
  };


  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  async function otpSend() {
    const body = {
      email: email,
    };

    await fetch(`${baseurl}/otp/send`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setContentShow("otpSection");
          setTimeout(() => {
            setButtonClicked(false);
          }, 2000);
           
        } else{
        }
      });
  }
  async function otpValidate() {
    const body = {
      email: email,
      otp:otp.join("")
    };

    await fetch(`${baseurl}/otp/validate`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setContentShow("passwordSection");
          setUserID(data.data[0]?.user_id)
          setOTP(Array(5).fill(""));
           
        } else{
        }
      });
  }

  async function passwordUpdate() {
    const body = {
      user_id: userID,
      new_pwd:password
    };

    await fetch(`${baseurl}/password/update`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setContentShow("successSection");
          setTimeout(() => {
            navigate("/login")
          }, 2000);
          setEmail("")
          setPassword("")
          setConfirmPassword("")
          setOTP(Array(5).fill(""));
          setUserID("")     
           
        } else{
        }
      });
  }

  return (
    <div className={style.container}>
      <div className={style.contentLeft}>
        <div className={style.resetCard}>
          {contentShow === "emailSection" ? (
            <div className={style.cardContent}>
              <div className={style.cardHeader}>
                <h4>Reset Password</h4>
                <span>
                  Receive a code in your email to reset your password.
                </span>
              </div>
              <div className={style.cardBody}>
                <div className={`${style.emailInput} ${emailError && style.errorInput}`}>
                  <input
                  type="text"
                   placeholder="Email"
                   name="Email"
                   required
                   value={email}
                   onChange={(e)=>{
                    setEmail(e.target.value)
                    setEmailError(false);
                   }}
                      />
                </div>
                <div className={style.cardButton}>
                  <button
                  disabled={buttonClicked}
                    onClick={()=>{
                      handleSendEmail();
                      setButtonClicked(true);
                    }}
                  >
                    Send
                  </button>
                </div>
                <div className={style.cardBottomText}>
                  <p>
                    Back to <span onClick={()=>{
                      navigate("/login")
                    }}>Login</span>
                  </p>
                </div>
              </div>
            </div>
          ) : contentShow === "otpSection" ? (
            <div className={style.cardContent}>
              <div className={style.cardHeader}>
                <h4>Reset Password</h4>
                <span>Enter the verification code below.</span>
              </div>
              <div className={style.cardBody}>
                <div className={style.otpSection}>
                {otp.map((_, i) => (
                  <div key={i} className={style.otpInput}>
                    <input
                      ref={(el) => (inputRefs.current[i] = el)}
                      maxLength={1}
                      required
                      value={otp[i]}
                      onChange={(e) => handleChange(e, i)}
                      onFocus={() => handleFocus(i)}
                    />
                  </div>
                ))}
                </div>
                {error && <p className={style.error}>{error}</p>}
                <div className={style.cardButton}>
                  <button
                    onClick={handleVerifyOtp}
                  >
                    Send
                  </button>
                </div>
                <div className={style.cardBottomText}>
                  <p>
                    Didn’t receive code? <span  onClick={handleSendEmail}>Resend</span>
                  </p>
                </div>
              </div>
            </div>
          ) : contentShow === "passwordSection" ? (
            <div className={style.cardContent}>
              <div className={style.cardHeader}>
                <h4>Reset Password</h4>
                <span>Enter a new, strong password!</span>
              </div>
              <div className={style.cardBody}>
                <div className={`${style.emailInput} ${passwordError && style.errorInput}`}>
                  <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Password" 
                  name="Password"
                  required
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(false);
                  }}
                  />
                   <span onClick={togglePasswordVisibility} className={style.eyeIco}>
                      {showPassword ? <RxEyeOpen /> : <RxEyeClosed />}
                    </span>
                </div>
                <div className={`${style.emailInput} ${confirmPasswordError && style.errorInput}`}>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    required
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordError(false);
                    }}
                  />
                </div>
                {passwordError && (
                  <div className={style.passwordCondition}>
                    <div className={style.specialCharacter}>*</div>
                    <div className={style.passwordConditionText}>
                      <p>
                        At least 8 characters, 1 uppercase letter,{" "}
                        <span>1 number</span> and{" "}
                        <span>1 special character.</span>
                      </p>
                    </div>
                  </div>
                )}
                
                <div className={style.cardButton}>
                  <button
                    onClick={handleResetPassword}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          ) : contentShow === "successSection" ? (
            <div className={style.successContent}>
                <div className={style.successImage}>
                    <img src={SuccessImage} alt="Success"/>
                </div>
                <div className={style.successText}>
                    <p>Your password has been changed successfully.</p>
                </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={style.contentRight}>
        <div className={style.rightImage}>
          <img src={LockImage} alt="Password reset" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
