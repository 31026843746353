import React,{useEffect,useState} from 'react'
import style from "./SubscriptionDetails.module.css"
import subscriptionImage from "../../assests/subscriptionMail.png"
import downloadIcon from "../../assests/DownloadIcon.png"
import { baseurl } from "../Url";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const SubscriptionDetails = () => {

  const navigate=useNavigate();

  const UserName=localStorage.getItem("username")
  const key = JSON.parse(localStorage.getItem("access-token"));
  const UserID = localStorage.getItem("USERID");

  const [subscriptionData,setSubscriptionData]=useState({})
  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);

  async function subscriptionDetails() {
    await fetch(`${baseurl}/subscribe/user/data`, {
      method: "POST",
      body: JSON.stringify({
        user_id:UserID
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.status===true){
          setSubscriptionData(data.data);
        }
        else{
          setSubscriptionData({})
        }
      });
  }

  useEffect(()=>{
    if(UserID){
      subscriptionDetails()
    }
  },[UserID])



  return (
    <div className={style.container}>
        <div className={style.subscription}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>Hello {UserName}!</p>
            <span>Take a look at your subscription plan.</span>
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={subscriptionImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>
        <div className={style.planSection}>
        <div className={style.planHeader}>
            <p>Subscription Plan</p>
        </div>
        <div className={style.subscriptionPlan}>
        <div className={style.subscriptionPlanTop}>
          {userDetails &&userDetails.user_type==="Trail"?
          <p onClick={()=>{
            navigate("/subscribe",{
              state:{
                Username: userDetails &&userDetails.Username,
                userID:userDetails && userDetails._id && userDetails._id.$oid,
                count:userDetails &&userDetails.trail_count
              }
            })
          }}>Subscription</p>:
            <p onClick={()=>{
              navigate("/subscribe",{
                state:{
                  Username: userDetails &&userDetails.Username,
                  userID:userDetails && userDetails._id && userDetails._id.$oid,
                  count:"1"
                }
              })
            }} >Renew Subscription </p>
          }
        </div>
        <div className={style.subscriptionPlanBottom}>
          <h4>{subscriptionData?.product_name||"Grammar Locker"} {subscriptionData?.amount_paid} {subscriptionData.currency}</h4>
          {userDetails &&userDetails.user_type==="Trail"?
         <> <p>
            {userDetails &&userDetails.Status==="Active"? "Active" : "Expired"}
          </p>
          <p>
              {userDetails &&userDetails.Status==="Active"? "Active" : "Expired"}{" "}
              {subscriptionData?.subscription_end_date
                ? dayjs(subscriptionData.subscription_end_date.$date || subscriptionData.subscription_end_date).format('MMMM D, YYYY')
                : ""}
              .
            </p>
          </>
          :
          <>
            <p>{subscriptionData?.subscription_status ? "Active" : "Expired"}</p>
            <p>
              {subscriptionData?.subscription_status ? "Active Until" : "Expired"}{" "}
              {subscriptionData?.subscription_end_date
                ? dayjs(subscriptionData.subscription_end_date.$date || subscriptionData.subscription_end_date).format('MMMM D, YYYY')
                : ""}
              .
            </p>
          </>}
        </div>

        </div>
        </div>
        {userDetails &&userDetails.user_type!=="Trail"?
        <div className={style.planSection}>
        <div className={style.planHeader}>
            <p> Billing Details</p>
        </div>
        <div className={style.subscriptionPlan}>
        <div className={style.subscriptionPlanTop}>
            <p>Change billing information </p>
        </div>
         <div className={style.subscriptionPlanBottom}>
            <h4>{subscriptionData?.FirstName}</h4>
            <p>{subscriptionData?.Email}</p>
        </div>
        <div className={style.subscriptionDetail}>
            <table className={style.detailTable}>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{subscriptionData?.product_name}</td>
                        <td>{subscriptionData?.ref_id}</td>
                        <td>{subscriptionData?.subscription_start_date
            ? dayjs(subscriptionData.subscription_start_date.$date || subscriptionData.subscription_start_date).format('DD/MM/YYYY')
            : 'N/A'}</td>
                        <td>{subscriptionData.amount_paid} INR</td>
                        <td><span style={{color:subscriptionData?.subscription_status ?"#10E1CB":""}}>{subscriptionData?.subscription_status ? "Active" : "Expired"}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className={style.downloadSection}>
        {/* <div className={style.downloadCard}>
            <p>Invoice</p>
            <img src={downloadIcon} alt='invoice'/>
        </div> */}
        <div className={style.downloadCard}>
            <p>Receipt</p>
            <img onClick={()=>window.open(subscriptionData.receipt_url,"_blank")} src={downloadIcon} alt='receipt'/>
        </div>
        </div>
        </div>
        </div>
        :null}
        </div>
    </div>
  )
}

export default SubscriptionDetails